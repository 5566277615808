import React, { Component } from 'react';
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import {TransitionGroup} from 'react-transition-group';
import './responsive.css';
import './App.css';
import Header from '../../Presentation/Header/Header';
import Sidenav from '../../Presentation/Sidenav/Sidenav';
import Home from '../Home/Home';
import About from '../About/About';
import Nominate from '../Nominate/Nominate';
import withTheme from '../Theme/Theme';
import {send, onReceive} from '../../../utils/networkWorker';


// helper function to limit max calls to scroll event
function throttleScroll(fn, ctx, lim=10){
  let self = this;
  let limit = lim;
  return function(ev){
    let args = arguments;
    if(limit == 1 || ev.target.scrollTop == 0){
      limit = lim;
      fn.apply(ctx, [...args]);
    }else{
      limit -= 1;
    }
  }
  
}


class App extends Component {

  componentDidMount(){
    this.lastScrollTop = 0;

    send({
      requestType:"GET_DESIGNER_DATA",
    });
    send({ requestType: "GET_DESIGNATIONS"});

    onReceive((e)=>{
      const {data} = e;
      switch(data.type){
        case "DESIGNER_DATA":
          this.updateDesigners(data.payload, data.init);
          break;
        case "DESIGNER_IMAGES":
          this.updateImages(data.payload);
          break;
        case "DESIGNATIONS":
          this.updateDesignations(data.payload);
          break;
        case "THEME":
          this.setState({theme: data.payload});
          break;
      }
    });
  }


  constructor(props){
    super(props);
    
    let state = {
      sidenav_visible: false,
      lastScrollTop: 0,
      header_visible: true,
      selected_designations: [],
      selectedAlphabet: '',
      selectedAlphabetId: null,
      requestActive: true,
      designations: [],
      designers:[],
      theme: "light"
    };
    this.state = state;
    this.toggleSidenav = this.toggleSidenav.bind(this);
    this.toggleSelectedCategory= this.toggleSelectedCategory.bind(this);
    this.detectScroll = this.detectScroll.bind(this);
  }



  updateDesigners = (designers, clean) =>{
    // set the data
    this.setState({
      designers: clean? designers : this.state.designers.concat(designers),
      requestActive: false
    }, ()=>{
      send({
        requestType:"GET_DESIGNER_DATA",
        index: this.state.designers.length
      });
    });

  }


  updateDesignations = (designations) => {
    this.setState(state=>{
      state.designations = designations
      return state;
    });
  }



  updateImages = (images) => {
    const updatedDesigners = this.state.designers.map((designer)=>{
      designer.data.picture = images[designer.id] || designer.data.picture;
      return designer;
    });
    this.setState({
      designers: updatedDesigners
    });
  }




  toggleSidenav(e){
    e.stopPropagation();
    let visible = this.state.sidenav_visible? false:true;
    this.setState({
      sidenav_visible: visible
    });
  }





  detectScroll(ev){
    let targetElement = ev.target;
    const {scrollTop} = targetElement;
    let header = document.querySelector(".app_header");

    if(scrollTop < 100){
      header.setAttribute("floating","false");
      return;
    }else{
      header.setAttribute("floating","true");
      
    }

    if( this.lastScrollTop > scrollTop){
      header.setAttribute("visible","true");
    }else{
      header.setAttribute("visible","false");
    }

    this.lastScrollTop = scrollTop <= 0?0:scrollTop;

  }





  toggleSelectedCategory(categoryId){
    
    this.setState(state =>{
      let selected_designations = state.selected_designations;
      let index = selected_designations.indexOf(categoryId);
      if( index > -1){
        selected_designations.splice(index,1);
      }else{
        selected_designations.push(categoryId);
      }
      // have to do something about this
      state.requestActive = true;
      return state;
    },()=>{
          let filtered_designations = this.state.selected_designations.map(id => {
            return this.state.designations.filter( desig => desig.id === id)[0].name || ""
          });
          
          filtered_designations.length? 
              send({requestType: "GET_FILTERED_DESIGNERS", filters: filtered_designations }):
              send({requestType: "RESET_FILTERS"})
    });


  }


  toggleTheme = (e) => {
    e.persist();
    e.stopPropagation();
    let {theme} = this.props.themeConfig;
    theme = theme === "light" ? "dark":"light";
    this.props.themeConfig.setTheme(theme);

  }



  render() {
    this.propTree = {
      'header':{  
                  toggleSidenav: this.toggleSidenav, 
                  visible: this.state.header_visible,
                  location: this.props.location,
                  selected_designations: this.state.selected_designations,
                  theme: this.props.themeConfig.theme
                },
      'sidenav':{ 
                  toggleSidenav:this.toggleSidenav, 
                  toggleTheme: this.toggleTheme,
                  visible: this.state.sidenav_visible, 
                  designations: this.state.designations, 
                  toggleSelectedCategory: this.toggleSelectedCategory, selected_designations: this.state.selected_designations
                }
              
    };
    return (
      <div className={`App overflow-h`} theme={this.props.themeConfig.theme} >
        <div loading={this.state.requestActive.toString()} className="waiter">
          <div className="waiter_loader"></div>
        </div>
        <Header {...this.propTree['header']}/>
          <div className="app_wrapper overflow-h">
            <TransitionGroup component="div">  
              <Switch>
                <Route exact path="/" render={()=>{ return <Home 
                designers={this.state.designers} 
                selectAlphabet={this.selectAlphabet} 
                selectedAlphabetId={this.state.selectedAlphabetId}
                detectScroll={throttleScroll(this.detectScroll)}
                ></Home>}} />
                <Route path="/about" component={About} />
                <Route path="/nominate" component={Nominate} />
                <Route render={()=>{return <Redirect to="/"></Redirect>}} />
              </Switch>
            </TransitionGroup>
          </div>

        <Sidenav {...this.propTree['sidenav']}/>
      </div>
    );
  }
}

//<Route exact path="/nominate" component={Nominate} />
export default withTheme(withRouter(App));
