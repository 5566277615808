import React from 'react';
import './checkbox.css';

const Checkbox = (props) =>{
    return (
        <div className="checkbox" onClick={(ev)=>{ ev.persist(); props.onClick(ev);  }}>
            <i className="material-icons">{props.checked?'check_box':'check_box_outline_blank'}</i>
            <span>{props.label}</span>
        </div>
    )
}

export default Checkbox;