import React from 'react';
import './card.css';
import Designations from '../Designations/Designations';


// helper function to get link icon from link
const setDisplay = (linkObject, link) =>{
    return linkObject[link] ? "block" : "none";
}

const isNew = (designerCreateDate, batchDate) =>
    new Date(designerCreateDate) >= new Date(batchDate)


const propDiff = (currProp, newProp) => {
    let currKeys = Object.keys(currProp);
    let nextKeys = Object.keys(newProp);

    if(currKeys.length !== nextKeys.length){
        return true;
    }

    // shallow check
    for(let i=0; i< currKeys.length; i++){
        let key = currKeys[i];

        if(currProp[key] !== newProp[key]){
            return true;
        }
    }

    return false;
}

class Card extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            picture: null
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.designer.picture !== prevState.picture){
            return {picture: nextProps.designer.picture}
        }
        else return null;
    }

    componentDidMount(){
        if(this.state.picture === null){

            this.setState(state => {
                state.picture = this.props.designer.picture;
                return state;
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.designer.picture !== this.state.picture;
    }

    componentDidUpdate(prevProps){
        if(prevProps.designer.picture !== this.state.picture){
            this.setState({
                picture: this.props.designer.picture
            });
        }
    }

    expand = () => {
        this.setState(state => {
            state.occupationsCopy = this.props.designer.occupations;
            state.truncated = false;
            return state;
        });
    }
    collapse = () => {
        this.setState(state => {
            state.occupationsCopy = this.props.designer.occupations.slice(0,2);
            state.truncated = true;
            return state;
        })
    }
    render(){
        return (
            <div className="app_card">
                {
                    isNew(
                        this.props.designer.createdAt,
                        this.props.designer.batchDate
                    ) && <div className="icon-new"></div> 
                }
                <div className="card_inner_content overflow-h">
                    <div className="card_image_placeholder" style={{backgroundImage: 'url('+this.state.picture+')'}}></div>
                    <div className="card_user_info">
                        <div className="card_head">
                            <h3>{this.props.designer.full_name}</h3>
                            <span>{this.props.designer.location.city}, {this.props.designer.location.country}</span>
                        </div>
                        <Designations 
                            designations={this.props.designer.occupations}
                        />
                        <p className="card_user_desc">
                            {this.props.designer.bio}
                        </p>
                    </div>
                </div>
                <div className="user_social">
                    <a rel="noreferrer" target="_blank" style={{display: setDisplay(this.props.designer.links,"web")}} href={this.props.designer.links["web"] || ""}>
                        <span className="icon web-icon"></span>
                    </a>
                    <a rel="noreferrer" target="_blank" style={{display: setDisplay(this.props.designer.links,"twitter")}} href={this.props.designer.links["twitter"] || ""}>
                        <span className="icon twitter-icon"></span>
                    </a>
                    <a rel="noreferrer" target="_blank" style={{display: setDisplay(this.props.designer.links,"linkedin")}} href={this.props.designer.links["linkedin"] || ""}>
                        <span className="icon linkedin-icon"></span>
                    </a>
                    <a rel="noreferrer" target="_blank" style={{display: setDisplay(this.props.designer.links,"medium")}} href={this.props.designer.links["medium"] || ""}>
                        <span className="icon medium-icon"></span>
                    </a>
                </div>
            </div>
        )
    }
}

export default Card;