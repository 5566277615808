import React from 'react';
//css
import './sidenav.css';
import Checkbox from '../Checkbox/Checkbox';

export default class Sidenav extends React.Component{
    constructor(){
        super();
        
    }
    render(){
        let sidenavClass = "sidenav_container overflow-h";
        sidenavClass += this.props.visible?'':' hidden';
        
        let sidenavOverlayClass = "sidenav_overlay";
        sidenavOverlayClass += this.props.visible?'':' overlay_hidden';
        
        return( 
            <div className="sidenav">
                <div onClick={this.props.toggleSidenav} className={sidenavOverlayClass}>
                    
                </div>
                <div className={sidenavClass}>
                    <div className="sidenav_header">
                        <div className="options_container">
                            {/* <button className="icon-button" onClick={this.props.toggleTheme}>
                                <span className="material-icons">invert_colors</span>
                            </button> */}
                        </div>
                        <div onClick={this.props.toggleSidenav} className="button_primary button_back">
                            <div>
                                <i className="material-icons">arrow_forward</i>
                            </div>
                        </div>
                    </div>
                    <div className="sidenav_content overflow-h">
                        <h3>Designations</h3>
                        <div className="sidenav_designations overflow-y">
                            {
                                this.props.designations.map((category)=>{
                                    return <Checkbox 
                                        key={category.id} 
                                        label={category.name} 
                                        onClick={(ev)=>{this.props.toggleSelectedCategory(category.id)}}
                                        
                                        checked={ (()=>{ 
                                            
                                            return this.props.selected_designations.indexOf(category.id) > -1 ?true:false })() 
                                        }
                                        
                                        />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}