import React, {useState} from 'react';

const Theme = (Component) => {
    return function withTheme(props){
        let appliedTheme = localStorage.getItem("theme");
        let [theme, setTheme] = useState(appliedTheme || "dark");
        const saveTheme = (theme) => {
            localStorage.setItem("theme",theme);
        }
        return <Component {...props} themeConfig={{
                theme, setTheme: (theme)=>{
                    saveTheme(theme);
                    setTheme(theme);
                }
            }}></Component>
    }
}

export default Theme;