import React from 'react';
import './about.css';

export default class About extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            managers: [
                {
                    name: "Ivy Mukherjee",
                    image: "ivy",
                    links: {
                        linkedin: "https://www.linkedin.com/in/ivy-mukherjee",
                        twitter: "https://twitter.com/JellyIvy",
                        web: "http://www.ivymukherjee.com/",
                        medium: "https://medium.com/@JellyIvy"
                    }
                },{
                    name: "Zeeshan Hyder",
                    image:"zee",
                    links:{
                        linkedin:"https://linkedin.com/in/hyderzeeshan",
                        twitter:"https://twitter.com/bzeeshanhyder",
                        web:"https://zeeshanhyder.dev",
                        medium:"https://medium.com/@zeeshan_hyder"
                    }
                },{
                    name: "Shashank Sahay",
                    image:"shashank",
                    links: {
                        linkedin:"https://www.linkedin.com/in/shashanksahayy/",
                        twitter:"https://twitter.com/shashanksahayy",
                        web:"https://dribbble.com/shashanksahay",
                        medium:"https://medium.com/@shashanksahay"
                    }
                }
            ]
        }
    }
    render(){
        return(
            <div className="about_container overflow-y">
                <div className="content_wrapper">
                    <div className="section about_this_section">
                        <h2 className="title_2 about_title">About this <span className="highlight">project</span></h2>
                        <p className="body_text about_description">
                            Indians Who Design is a living directory of thriving Indians in the design industry. Our mission is to provide a space to find out outstanding people to follow, look for mentor, make new friends, or discover talented individuals to join our team. 
                        </p>
                        <div className="video_placeholder"></div>
                    
                    </div>
                    <div className="section request_edit_section">
                        <h3 className="title_3">Request an edit</h3>
                        <p className="body_text">
                            If you have been added to the directory and would like to opt out or make an edit to your profile, please send us a message on Twitter to <a href="https://twitter.com/indwhodesign" rel="noreferrer" target="_blank">@indwhodesign</a>.
                        </p>
                    </div>
                    <div className="section team_section">
                        <h3 className="title_3">Team behind this</h3>
                        <div className="iwd_team_wrapper">
                            {
                                this.state.managers.map((manager) => {
                                    return( 
                                    <div key={manager.name} className="team_member">
                                        <div className={`member_image card_image_placeholder ${manager.image}`}></div>
                                        <div className="dog_tag">
                                            <h3 className="member_name">{
                                                manager.name
                                            }</h3>
                                            <div className="user_social">
                                                <a rel="noreferrer" target="_blank" href={manager.links.web}><span className="icon web-icon"></span></a>
                                                <a rel="noreferrer" target="_blank" href={manager.links.twitter}><span className="icon twitter-icon"></span></a>
                                                <a rel="noreferrer" target="_blank" href={
                                                    manager.links.linkedin
                                                }><span className="icon linkedin-icon"></span></a>
                                                <a rel="noreferrer" target="_blank" href={
                                                    manager.links.medium
                                                }><span className="icon medium-icon"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}