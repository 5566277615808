import React from 'react';
import './grid.css';
import {Link} from 'react-router-dom';
// presentation component
import Card from '../../Presentation/Card/Card';


export default class Grid extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            designers: []
        }
    }

    componentDidMount(){
        this.setState({
            designers: this.props.designers || []
        });
    }
 
    componentWillReceiveProps(updatedProps){
        this.setState({
            designers: updatedProps.designers
        });
    }
    render(){
        return (
            <div style={this.props.style} className="app_grid">
                <div className="logo">
                    <h3>Indians <br/> Who <span className="highlight">Design</span></h3>
                    <div>
                    <a href='https://twitter.com/indwhodesign' target='_blank' rel='noreferrer'>
                        <img src='static/twitter_white.svg' className='twitter_link_mob' alt="twitter"/>
                    </a>
                    </div>
                </div>
                {
                    
                    this.state.designers.map((designer)=>{
                        return <Card key={designer.id} designer={designer.data} />
                    })
                }
            </div>
        )
    }
}