import React, { useState } from 'react';

// helper functions to trim designations
const collapsable = (designations) => {
    const letterCount = designations.join("").length;
    return (letterCount >= 33 && designations.length > 2) ? true: false;
}
const collapse = (designations) => {

    let letterCount = designations.slice(0,2).join("").length;
    if(letterCount >= 25){
        return designations.slice(0,1);
    }
    return designations.slice(0,2);
}

const Designations = ({ designations }) => {
    const [expanded, setExpanded] = useState(false);
    const [localDesignations, setDesignations] = useState(
        collapsable(designations)?collapse(designations):designations
        );
    return (
        <div className="card_tags">
            {
                localDesignations.map((designation) => {
                    return <span key={Math.random() * 100}>{designation}</span>
                })
            }
            {!expanded && collapsable(designations) &&
                <span style={{ cursor: "pointer" }} onClick={() => {
                    setDesignations(designations);
                    setExpanded(true);
                }}>
                    <i className="material-icons">more_horiz</i>
                </span>
            }
            {expanded && collapsable(designations) &&
                <span style={{ cursor: "pointer" }} onClick={() => {
                    setDesignations(collapse(designations))
                    setExpanded(false);
                }}>
                    <i className="material-icons">close</i>
                </span>
            }
        </div>
    )
}
export default Designations;