import React from 'react';
import {Link} from 'react-router-dom';

// css
import './Header.css';

const Header = (props) => {
    return(
        <div 
            className="app_header" 
            visible={""+props.visible}
            style={ props.location.pathname !== '/' ? {position:'relative'}:{} } >
            <div className="header_left">
                <div></div>
                <Link style={{
                        display:"flex", 
                        height:"100%",
                        flexDirection:"column", 
                        justifyContent:"center",
                        alignSelf:"end"}} className="logo" to='/'>
                    <div className="logo-icon"></div>
                </Link>
                <div></div>
            </div>
            <div className="header_right">
                <nav className="main_nav_panel">
                    <a href='https://twitter.com/indwhodesign' target='_blank' rel='noreferrer'>
                        <img src='static/twitter_white.svg' className='twitter_link' alt="twitter"/>
                    </a>
                    <Link to='/about'>About</Link>
                    <Link to='/nominate'>Nominate</Link>
                </nav>
                <div style={props.location.pathname !== "/" ? {display:"none"}: {} } onClick={props.toggleSidenav} className="button_primary button_filter">
                    <div>
                        <i className="material-icons">filter_list</i>
                        <span className="title">Filter</span>
                        {props.selected_designations.length > 0 && <i className="dot"></i> }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;