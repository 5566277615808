import React from 'react';
import Grid from '../Grid/Grid';

export default class Home extends React.Component {
    constructor(props){
        super(props);
    
    }


    componentDidMount(){

    }

    render(){
        return(
            <div className="overflow-h" style={{display: "flex", flex: "1 1 auto", flexDirection:"row"}}>
                <div onScroll={(e)=>{ e.persist(); this.props.detectScroll(e)}}  className="app_content overflow-y" style={{flex: "1 1 auto"}}>
                    <Grid designers={this.props.designers}/>
                </div>
            </div>
            
        )
    }
}