import React from 'react';
import './nominate.css';


export default class Nominate extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <div className="nominate-container overflow-y">
                <div className="content_wrapper">
                    <div className="section this_section">
                        <h1 className="title_2">Nominate a <span className="highlight">designer</span></h1>
                        <p className="body_text">
                           Help us build the directory of Indians thriving in the field of design. If you know someone who deserves to be on the list, nominate them by clicking the Nominate button and filling the form. And hey, it's alright to nominate yourself. 😊
                        </p>
                        <a href="https://forms.gle/chAvv9qKrsPGTtGw5" rel='noreferrer' target="_blank" className='nominate_button button_primary'>Nominate</a>
                    </div>
                </div>
            </div>
        );
    }
}